import { useState } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { oneLine } from 'common-tags';

import { ArrowLeft, ArrowRight } from '@/icons';
import { Container } from '@/atoms';
import { Category, WithPaperBorder } from '@/molecules';
import { Positions } from '@/molecules/with-paper-border/types';

import type { FunctionComponent } from 'react';
import type { TypeListOfCategoriesImagesProps } from './types';

/**
 * ListOfCategoriesImages
 */
export const ListOfCategoriesImages: FunctionComponent<
  TypeListOfCategoriesImagesProps
> = ({
  papelRasgado,
  showArrow,
  title,
  theme,
  desktopCategories = [],
  mobileCategories = [],
  id,
}: TypeListOfCategoriesImagesProps) => {
  const [content, setContent] = useState(
    mobileCategories.length === 0 ? desktopCategories : mobileCategories,
  );

  return (
    <WithPaperBorder
      id={id}
      border={papelRasgado?.border}
      borderColor={papelRasgado?.borderColor}
      borderHeight={papelRasgado?.borderHeight}
      top={
        papelRasgado?.zona === Positions.top ||
        papelRasgado?.zona === Positions.topBottom
      }
      bottom={
        papelRasgado?.zona === Positions.bottom ||
        papelRasgado?.zona === Positions.topBottom
      }
    >
      <Container
        id={id}
        padding="p-0"
        wrapper
        style={{
          backgroundColor:
            theme === 'dark'
              ? 'var(--tw-color-bg-tertiary)'
              : 'var(--tw-color-bg-primary)',
          color:
            theme === 'dark'
              ? 'var(--tw-color-bg-secondary)'
              : 'var(--tw-color-primary-600)',
        }}
      >
        {/**
         * TODO: La versión que tenemos de Splide no tiene el prop Children ya que en React 17 se añadia automaticamente pero en la 18 no
         * Cuando actualicemos Splide a la última versión, podemos quitar el @ts-ignore
         * @eslint-disable-next-line @typescript-eslint/ban-ts-comment
         * @ts-expect-error types are not for react 18 */}
        <Splide
          options={{
            type: 'slide',
            pagination: false,
            drag: true,
            arrows: showArrow,
            lazyLoad: true,
            perMove: 1,
            perPage: 8,
            breakpoints: {
              360: {
                perPage: 3,
              },
              768: {
                perPage: 4,
              },
              1024: {
                perPage: 5,
              },
              1440: {
                perPage: 7,
              },
            },
          }}
          className="flex flex-col-reverse gap-6"
          onMounted={() => {
            if (window.matchMedia('(min-width: 1024px)').matches) {
              setContent(desktopCategories);
            }
          }}
          {...((title || showArrow) && {
            renderControls: () => (
              <div className="splide__arrows flex flex-row items-center justify-between gap-6">
                {title && <p className="u-headline u-headline--h2">{title}</p>}
                {showArrow && (
                  <div className="flex w-max grow flex-row justify-end gap-3 self-end max-lg:mx-4">
                    <ArrowLeft
                      width={36}
                      height={36}
                      className={oneLine`splide__arrows splide__arrow--prev cursor-pointer rounded-full border border-dashed p-2 transition-colors disabled:pointer-events-none disabled:opacity-20 ${
                        theme === 'light'
                          ? 'text-primary-900 hover:border-solid hover:border-primary-900 hover:bg-primary-900 hover:text-white'
                          : 'text-white hover:border-solid hover:border-white hover:bg-white hover:text-primary-900'
                      } `}
                      role="tab"
                      aria-label="Anterior slide"
                    />

                    <ArrowRight
                      width={36}
                      height={36}
                      className={oneLine`splide__arrows splide__arrow--next cursor-pointer rounded-full border border-dashed p-2 transition-colors disabled:pointer-events-none disabled:opacity-20 ${
                        theme === 'light'
                          ? 'text-primary-900 hover:border-solid hover:border-primary-900 hover:bg-primary-900 hover:text-white'
                          : 'text-white hover:border-solid hover:border-white hover:bg-white hover:text-primary-900'
                      } `}
                      role="tab"
                      aria-label="Siguiente slide"
                    />
                  </div>
                )}
              </div>
            ),
          })}
        >
          {content.map((content, i) => (
            <SplideSlide key={i}>
              <Category
                {...content}
                imageSizes="(max-width: 360px) 33vw, (max-width: 768px) 25vw, (max-width: 1024px) 20vw, (max-width: 1440px) 14.25vw, 12.5vw"
              />
            </SplideSlide>
          ))}
        </Splide>
      </Container>
    </WithPaperBorder>
  );
};

ListOfCategoriesImages.displayName = 'ListOfCategoriesImages';
